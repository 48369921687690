import dayjs from 'dayjs'

import { PATH_PROMOTION_URL, PATH_ICON_HOT_URL } from '~/config/constant'
import IconDescription from '~/components/desktop/pages/promotion/vip/icons/description.vue'
import IconCoCau from '~/components/desktop/pages/promotion/vip/icons/cocau.vue'
import IconRule from '~/components/desktop/pages/promotion/vip/icons/rule.vue'
import IconCondition from '~/components/desktop/pages/promotion/vip/icons/condition.vue'
import IconTableBonus from '~/components/desktop/pages/promotion/vip/icons/table-bonus.vue'

export const TEXT_PROMOTION = {
  TITLE: 'Khuyến mãi và Sự kiện hot nhất tháng ' + dayjs().format('MM/YYYY'),
  TITLE_MB: 'Khuyến mãi',
  VIP_ID: 'vip',
  DETAIL: 'Chi Tiết',
  EMPTY_PROMOTION: 'Chưa có khuyến mãi',
  NOTE_EMPTY: 'Quý khách vui lòng quay lại sau'
}

export const RANGES = [
  {
    text: 'Thể thao',
    key: 'sport'
  },
  {
    text: 'E-Sports',
    key: 'e_sport'
  },
  {
    text: 'Thể thao Ảo',
    key: 'virtual_sport'
  },
  {
    text: 'Live Casino',
    key: 'casino'
  },
  {
    text: 'Nổ hũ',
    key: 'no_hu'
  },
  {
    text: 'Keno',
    key: 'keno'
  },
  {
    text: 'Lô đề',
    key: 'lode'
  },
  {
    text: 'Game bài',
    key: 'game_bai'
  },
  {
    text: 'Bắn cá',
    key: 'fishing_no_hu'
  }
]

export const LEVEL = [
  {
    text: 'Thể loại'
  },
  {
    text: 'Vip 1',
    thumb: `${PATH_PROMOTION_URL}1.webp`
  },
  {
    text: 'Vip 2',
    thumb: `${PATH_PROMOTION_URL}2.webp`
  },
  {
    text: 'Vip 3',
    thumb: `${PATH_PROMOTION_URL}3.webp`
  },
  {
    text: 'Vip 4',
    thumb: `${PATH_PROMOTION_URL}4.webp`
  },
  {
    text: 'Vip 5',
    thumb: `${PATH_PROMOTION_URL}5.webp`
  }
]

export const MENU_TABS = [
  {
    icon: `${PATH_PROMOTION_URL}menu/structure.svg`,
    name: 'Cơ cấu',
    tab: 'structure'
  },
  {
    icon: `${PATH_PROMOTION_URL}menu/condition.svg`,
    name: 'Điều kiện thưởng',
    tab: 'condition'
  }
]

export const TEXT_DETAIL = {
  TEXT_STRUCTURE: 'Cơ cấu',
  ICON_STRUCTURE: `${PATH_PROMOTION_URL}menu/structure.svg`,
  TEXT_CONDITION: 'Điều kiện thưởng',
  ICON_CONDITION: `${PATH_PROMOTION_URL}menu/condition.svg`,
  TEXT_OVERVIEW: 'Tổng quan',
  ICON_OVERVIEW: `${PATH_PROMOTION_URL}menu/overview.svg`,
  TEXT_ABOUT: 'Giới thiệu',
  ICON_ABOUT: `${PATH_PROMOTION_URL}menu/about.svg`,
  TEXT_HOWGET: 'Cách nhận thưởng VIP',
  ICON_HOWGET: `${PATH_PROMOTION_URL}menu/get.svg`,
  BANNER_MB: `${PATH_PROMOTION_URL}vip-promotion-mb.webp`,
  STAR: `${PATH_PROMOTION_URL}menu/star.svg`,
  TITLE_RANK: 'THƯỞNG LÊN HẠNG',
  TEXT_PROMOTION: 'Khuyến mãi',
  TEXT_VIPBIGC: `VIP ${process.env.BRANCH_NAME}`,
  TEXT_JOIN: 'Tham gia ngay',
  TITLE_PRIVILEGE: 'ĐẶC QUYỀN HOÀN TRẢ VIP',
  TEXT_VIP: 'VIP'
}

export const EMPTY_DATA = {
  icon: `${PATH_ICON_HOT_URL}sport-empty.webp`,
  text: 'Dữ liệu đang cập nhật, quý khách vui lòng quay lại sau!'
}

export const TAB_ACTION = {
  OVERVIEW: 'overview',
  RULE: 'rule',
  COCAU: 'cocau',
  DESCRIPTION: 'description',
  HOWJOIN: 'howjoin',
  CONDITION: 'condition',
  BONUS: 'bonus'
}

export const TAB_ACTION_VIP = {
  OVERVIEW: 'overview',
  ABOUT: 'about',
  HOWGET: 'howget'
}

export const PROMOTIONS_DES_TAB = {
  icon: markRaw(IconDescription),
  name: 'Mô tả',
  tab: 'description',
  content: ''
}

export const PROMOTIONS_COCAU_TAB = {
  icon: markRaw(IconCoCau),
  name: 'Cơ Cấu Giải Thưởng',
  tab: 'cocau',
  content: ''
}

export const PROMOTIONS_THELE_TAB = {
  icon: markRaw(IconRule),
  name: 'Thể lệ',
  tab: 'rule',
  content: ''
}
export const PROMOTIONS_DK_TAB = {
  icon: markRaw(IconCondition),
  name: 'Điều khoản & Điều Kiện',
  tab: 'condition',
  content: ''
}
export const PROMOTIONS_TABLE_BONUS_TAB = {
  icon: markRaw(IconTableBonus),
  name: 'Bảng thưởng',
  tab: 'bonus',
  content: ''
}
